import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { RxHamburgerMenu } from 'react-icons/rx';
import { FiLogOut } from 'react-icons/fi';

const Header = () => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <header className="w-full bg-transparent p-4 px-6 md:px-8 flex justify-between items-center border-b border-gray-200">
      <a href="/" className="flex items-center">
        <img alt='icon' src='../getcabinspace_logo.png' className='w-28 md:w-32 h-auto' />
      </a>
      <div className="relative">
        {/* Hamburger Icon */}
        <button 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-expanded={isMenuOpen}
          className="md:hidden text-gray-700 focus:outline-none"
        >
          <RxHamburgerMenu className="text-2xl" />
        </button>

        {/* Mobile Menu */}
        <nav 
          className={`md:flex md:space-x-10 absolute top-full right-0 mt-2 ${isMenuOpen ? 'w-32 text-sm' : 'w-full'} bg-white shadow-lg md:shadow-none ${isMenuOpen ? 'block' : 'hidden'} md:static z-10`}
        >
          <div className="flex flex-col md:flex-row items-center p-4 space-y-2 md:space-y-0 md:space-x-6">
            <a href="/" className="text-sm sm:text-sm md:text-lg lg:text-xl">Our Cabins</a>
            {/* Separator for mobile view */}
            {isMenuOpen && (
              <div className="w-full border-t border-gray-300 md:hidden"></div>
            )}
            <a href="/aboutus" className="text-sm sm:text-sm md:text-lg lg:text-xl">About Us</a>
          </div>
          {user && (
        <div className="flex sm:text-center justify-center sm:pb-2 sm:mt-0  items-center mt-2 md:mt-0 ml-auto md:ml-2">
          {/* Adjusted margin for large screens */}
          <button className="flex items-center space-x-1 text-sm" onClick={handleLogout}>
            <FiLogOut className="text-xl" /> <span>Logout</span>
          </button>
        </div>
      )}
        </nav>
      </div>
     
    </header>
  );
};

export default Header;
